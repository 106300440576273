<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('TRANSLATIONS.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyTranslationsIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <entity-form />
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTCard from '../../../content/Card'
import EntityForm from '../../../../components/company/translations/Form'

export default {
  name: 'CompanyTranslationsCreate',
  components: {
    EntityForm,
    KTCard, 
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('TRANSLATIONS.TITLE_NEW') }])
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
